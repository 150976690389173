import { useState } from "react";
import "bulma/css/bulma.min.css";
import { Navbar, Container } from "react-bulma-components";

import { SongsMenu } from "./SongsMenu";
import { SongContent } from "./SongContent";
import { createSongRepository } from "./SongRepository";

const SongRepository = createSongRepository();

export function App() {
  const [liricsContent, setLiricsContent] = useState("");
  const [selectedSong, setSelectedSong] = useState(null);

  const [songsMenuShouldBeShown, setSongsMenuShouldBeShown] = useState(
    selectedSong === null
  );

  function onSongSelect(song) {
    SongRepository.getLyricsForSong(song).then((lyrics) => {
      setSelectedSong(song);
      setLiricsContent(lyrics);
      window.scrollTo(0, 0);
      setSongsMenuShouldBeShown(false);
    });
  }

  return (
    <>
      <Navbar color="primary" fixed="top">
        <Navbar.Brand>
          <Navbar.Item
            renderAs="string"
            dangerouslySetInnerHTML={{
              __html: selectedSong != null ? selectedSong : "Kantutegia",
            }}
            style={{ maxWidth: "calc(100% - 3.25rem)" }}
          />

          <Navbar.Burger
            onClick={() => setSongsMenuShouldBeShown(!songsMenuShouldBeShown)}
            display="block"
          />
        </Navbar.Brand>
      </Navbar>
      <Container>
        {(songsMenuShouldBeShown || selectedSong === null) && (
          <SongsMenu
            onSongSelect={onSongSelect}
            SongRepository={SongRepository}
          />
        )}
        {!(songsMenuShouldBeShown || selectedSong === null) && (
          <SongContent
            selectedSongContent={liricsContent}
            selectedSongAudios={SongRepository.getAudiosForSong(selectedSong)}
          />
        )}
      </Container>
    </>
  );
}
