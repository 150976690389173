export function createSongRepository() {
  var songListCache = [];
  return {
    getSongs: async function () {
      if (songListCache.length === 0) {
        const songJsonListResponse = await fetch(
          "https://kantutegia.ortzadar.eus/abestiak/abestiak.json"
        );
        songListCache = await songJsonListResponse.json();
      }
      return Object.keys(songListCache);
    },
    getLyricsForSong: async function (songName) {
      return fetch("https://kantutegia.ortzadar.eus/abestiak/" + songName).then(
        (response) => response.text()
      );
    },
    getAudiosForSong: function (songName) {
      return songListCache[songName];
    },
  };
}
