import { Panel } from "react-bulma-components";

export function SongContent({ selectedSongContent, selectedSongAudios = [] }) {
  return (
    <Panel>
      {selectedSongAudios.length > 0 && <Panel.Header>
        {selectedSongAudios.map(audioSrc=>(
            <div key={audioSrc}>
          <audio
            controls
            src={
              "https://kantutegia.ortzadar.eus/abestiak/audioak/" +audioSrc
            }
            preload="auto"
            controlsList="nodownload"
          />
          </div>)
        )}
      </Panel.Header>}
      <Panel.Block
      >
        <div dangerouslySetInnerHTML={{
          __html: selectedSongContent.replace(/\n/gmi,"<br>").replace(/(\*)([^\*]*)(\*)/gmi,"<strong>$2</strong>")
        }}/>
      </Panel.Block>
    </Panel>
  );
}
