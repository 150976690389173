import { useEffect, useState } from "react";
import { Form, Icon, Panel } from "react-bulma-components";

export function SongsMenu({ onSongSelect, SongRepository }) {
  const [inputValue, setInputValue] = useState("");
  const [Abestiak, setAbestiak] = useState([])

  useEffect(()=>{
    SongRepository.getSongs().then(setAbestiak);
  },[])

  const handleOnSongClick = (song) => {
    return function (ev) {
      onSongSelect(song);
    };
  };

  const ErakustekoAbestiak =
    inputValue.length <= 0
      ? Abestiak
      : Abestiak.filter((abestia) => {
          return abestia.toLowerCase().includes(inputValue.toLowerCase());
        });

  return (
    <Panel>
      <Panel.Header>
        <Form.Control>
          <Form.Input
            type="text"
            value={inputValue}
            onChange={(ev) => {
              setInputValue(ev.target.value);
            }}
          />
          <Icon align="right" size="small">
            <i className="fas fa-search" />
          </Icon>
        </Form.Control>
      </Panel.Header>

      {ErakustekoAbestiak.map((bu) => {
        return (
          <Panel.Block key={bu} onClick={handleOnSongClick(bu)}>
            {bu}
          </Panel.Block>
        );
      })}
    </Panel>
  );
}
